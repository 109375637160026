import * as React from 'react';
import Layout from '../components/layout/layout';
import Seo from '../components/seo';
import { Box, ResponsiveContext } from 'grommet';

const PrivacyPage = () => {
  return (
    <Layout>
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <>
              <Seo title="情報セキュリティについて" />
              <Box
                style={{
                  marginTop: '4rem',
                  marginBottom: '2.5rem',
                  lineHeight: '1.8',
                  paddingLeft: size !== 'xsmall' && size !== 'small' ? '200px' : '10px',
                  paddingRight: size !== 'xsmall' && size !== 'small' ? '200px' : '10px',
                }}
              >
                <Box
                  direction="row"
                  justify="center"
                  align="center"
                  style={{ marginBottom: '1.5rem' }}
                >
                  <h1 style={{ fontSize: '1.5rem', marginBottom: '0rem' }}>
                    情報セキュリティについて
                  </h1>
                </Box>
                <Box pad="medium">
                  <Box style={{ marginBottom: '1rem' }}>
                    <span>
                      FORKLOREシステムはマイクロソフト社の「Microsoft Azure」を利用しています。
                    </span>
                    <span>
                      {
                        'Microsoft AzureはFortune 500企業の95%が信頼できるクラウドサービス※として利用しているコンピューティングプラットフォームです。'
                      }
                    </span>
                    <span>
                      ※参照:
                      <a href="https://azure.microsoft.com/ja-jp/overview/what-is-azure/">
                        {'Microsoft Azure 公式ホームページ'}
                      </a>
                    </span>
                    <span>
                      FORKLOREでは、Azureが提供する様々なセキュリティ製品を利用しています。
                    </span>
                  </Box>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>
                      <span>{'機密情報を暗号化して保管'}</span>
                      <span>
                        {
                          'ユーザーの個人情報など機密性の高い情報は暗号化して保管し、のぞき見されないように管理しています。'
                        }
                      </span>
                    </li>
                    <li>
                      <span>{'多要素ユーザー認証を適用'}</span>
                      <span>
                        {
                          'ユーザーアカウントのパスワード漏洩があった場合でも、不正なアクセスをさせないようログイン毎に本人確認を要求します。'
                        }
                      </span>
                    </li>
                    <li>
                      <span>{'同時接続制限を適用'}</span>
                      <span>
                        {
                          '万が一、不正アクセスがあった場合は、同一アカウントによる複数端末からの同時接続を制限します。'
                        }
                      </span>
                    </li>
                  </ul>
                  <Box style={{ marginBottom: '1rem' }}>
                    <span>
                      {
                        'FORKLOREシステムは外部機関による脆弱性診断(セキュリティ診断)を受けています。'
                      }
                    </span>
                    <span>
                      {'診断で洗い出された潜在的なセキュリティリスクの潰し込みをしています。'}
                    </span>
                    <span>
                      {
                        'FORKLOREは、株式会社豊田自動織機トヨタL&Fカンパニーの個人情報保護方針に基づき、個人情報について細心の注意と最大限の努力をもって、保護、管理に尽力していきます。'
                      }
                    </span>
                  </Box>
                  <p>
                    <span>
                      <a href="http://www.toyota-lf.com/privacypolicy/">
                        {'個人情報保護方針　(豊田自動織機トヨタL&FカンパニーHP)'}
                      </a>
                    </span>
                  </p>
                </Box>
              </Box>
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
};
export default PrivacyPage;
